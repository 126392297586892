@media screen and (min-width: 1278px) {
    .half-center h1 {
        font-size: 128px;
        margin-bottom: -36px;
    }
    .container {
        max-width: 1074px;
        margin-left: auto;
        margin-right: auto;
        padding: 70px 0 70px 0;
    }
}

@media screen and (min-width: 641px) {
    /* .arrow {
        border-width: 0 2vh 2vh 0;
        padding: 1.75vh;
    }
    nav a {
        color: var(--bg);
        transition: color .3s;
    }
    nav a:hover {
        color: white
    }
    nav a:hover .arrow {
        border: solid white;
        border-width: 0 2vh 2vh 0;
    }
    nav div {
        position: absolute;
        top: 4vh;
        right: 4vh;
        text-align: right;
    }
    nav svg {
        position: absolute;
        top: 0;
        right: 0;
        height: 40vh;
        width: 40vh;
        fill: var(--accent)
    }
    nav h3 {
        font-size: 3vh;
    }
    nav h1 {
        font-size: 4vh;
    }
    nav h1, h3 {
        margin: 5;
        text-transform: uppercase;
    } */
    .card {
        max-width: 400px;
        margin: 30px;
    }
    .card h4{
        font-size:20px;
    }
    .card h3 {
        font-size: 22px;
    }
    .card p {
        font-size: 20px;
    }
    .inner {
        padding: 20px 30px 20px 30px;
    }
    .bottom {
        font-size: 18px;
        ;
        padding: 20px;
    }
    .bottom i {
        border-width: 0 .75vh .75vh 0;
        padding: .75vh;
    }
    .text {
        padding-left: 40px;
    }
    .experience-card {
        padding: 20px;
        border-left: 5px var(--accent) solid;
        justify-content: flex-start;
    }
    .titles, .text {
        display: inline-block;
        width: 49%;
        vertical-align: top;
    }
    .small-socials{
        position: absolute;
        top:0px;
        left:0px;
        width:100vw;
        text-align: right;
        font-size: 36px;
    }
    .small-socials a{
        text-decoration: none;
        color:white;
        padding-left:15px;
        transition: opacity .3s
    }
    .small-socials a:hover{
        opacity: .5;
    }
    .small-socials .container{
        padding-top:20px;
        padding-bottom:0px;
    }
}

@media (max-aspect-ratio: 1/1) {
    .half-center{
        height: 28vh;
        background: linear-gradient(180deg, var(--bg) 35%, var(--accent) 100%);
    }
}

@media screen and (max-width: 640px) {
    h2 {
        font-size: 36px;
    }
    .experience-card {
        margin-bottom: 0px;
        margin-top: 32px;
        border-top: 5px var(--accent) solid;
    }
    .bottom {
        font-size: 13px;
        padding: 15px;
    }
    .bottom i {
        border-width: 0 .55vh .55vh 0;
        padding: .55vh;
    }
    .inner {
        padding: 0px 15px 12px 15px;
    }
    .card {
        max-width: 75vw;
        margin-right: 7vw;
        box-shadow: 0px 0px 4px 0px var(#999);
    }
    .overflow{
        padding-left:7vw;
        padding-right:10px;
        padding-top:10px;
        padding-bottom:10px;
    }
    .card h4{
        font-size:14px;
        margin-top:12px;
    }
    .card h3 {
        font-size: 16px;
    }
    .card p {
        font-size: 16px;
    }
    /* .arrow {
        border-width: 0 1.25vh 1.25vh 0;
        padding: 1.75vh;
    }
    nav svg {
        display: none;
    }
    nav a {
        color: var(--bg);
        text-decoration: none;
    }
    nav {
        background-color: var(--accent);
        padding: 7vw;
    }
    .arrow {
        float: right;
        display: inline-block;
        margin-top: -45px;
    }
    nav h1, nav h3 {
        margin: 0px;
    } */
    .small-socials {
        background-color: var(--accent);
        font-size: 30px;
        text-align: left;
    }
    .small-socials .container{
        padding-top:4vw;
        padding-bottom: 4vw;
    }
    .small-socials a{
        margin-right:20px;
        text-decoration: none;
        color: var(--bg);
    }
    .small-socials a:focus, .small-socials a:hover{
        color:white
    }
    .half-center {
        height: 33vh;
        padding: 7vw;
        background: var(--bg);
        /* background: linear-gradient(180deg, #943156 10%, #0f3443 150%); */
    }
    .half-center h1 {
        font-size: 15vw;
        text-align: left;
    }
    .container {
        padding: 7vw;
    }
    .container p {
        font-size: 20px;
    }
    .tri {
        width: 100%;
        border-top: 5px var(--accent) solid;
        margin-bottom: 28px;
    }
    .tri h4{
        font-size: 20px;
    }
    .contact-bar{
        font-size: 30px;
    }
    .social{
        width:100%;
    }
    .social i{
        margin-left: 0px;
    }
    .social h3{
        font-size:18px;
    }
    footer p{
        font-size: 14px !important;
    }
}